@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  @apply text-base font-medium text-black-700 md:text-base-xl;
}

input {
  @apply outline-none;
}

h2 {
  @apply text-h2-sm font-extrabold text-black-900 md:text-h2-md;
}

h3 {
  @apply text-h3-sm font-extrabold text-black-900 md:text-h3-md;
}

h4 {
  @apply text-h4-sm font-extrabold text-black-900 md:text-h4-md;
}

.gm-marker {
  position: absolute;
  bottom: 45px;
  left: 20px;
  color: #ea4335 !important;
}

.container-base {
  @apply mx-auto w-full max-w-full md:max-w-[680px] lg:w-[97%] lg:max-w-[1140px];
}

.container {
  @apply container-base p-4 md:px-0;
}

.main-image {
  font-size: 1em;
  --gap: 3em;
}

.side-image {
  font-size: 0.6em;
  --gap: 0.85em;
}